import React from 'react'
import logo from '../../images/node.svg'

const Nodejs = () => {
  return (
    <div className="tech-img color-dark-bg">
      <img className="nodejs" src={logo} alt="Node.js" width="120" height="60"></img>
    </div>
  )
}

export default Nodejs