import React from "react"
import Reactjs from "../components/techs/react";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Reactnative from "../components/techs/reactnative";
import Gatsby from "../components/techs/gatsby";
import Nextjs from "../components/techs/next";
// import Ticker from 'react-ticker'
import Nodejs from "../components/techs/node";
import Graphql from "../components/techs/graphql";
import AWS from "../components/techs/aws";
import Firebase from "../components/techs/firebase";
import Azure from "../components/techs/azure";
import GCP from "../components/techs/gcp";
import XD from "../components/techs/adobexd";
import Figma from "../components/techs/figma";
import {Link} from "gatsby";

const Tech = () => (
  <Layout>
    <SEO title="Reactjs, Gatsbyjs, Nextjs, Firebase, Cisco and more" />
    <div className="page-container services">
      <h2 className="page-header color-0 center">Tech We Use</h2>
      <section>
        <div className="container2">
          <div className="section-head color-1">
            {/* <div className="section-head-icon-container">
              <FaLaptopCode className="section-head-icon"/>
            </div>  */}
            <h4 className="section-head-text">App Development</h4>
          </div>
          <marquee className="techs" width="100%">
            <Reactjs />
            <Gatsby />
            <Nextjs />
            <Graphql />
            <Nodejs />
            <Reactnative />
          </marquee>
        </div>
      </section>
      <section>
        <div className="container2">
          <div className="section-head color-1">
            {/* <div className="section-head-icon-container">
              <FaLaptopCode className="section-head-icon"/>
            </div>  */}
            <h4 className="section-head-text">Cloud Computing</h4>
          </div>
          <marquee className="techs" width="100%" direction="right">
            <AWS />
            <Firebase />
            <Azure />
            <GCP />
          </marquee>
        </div>
      </section>
      <section>
        <div className="container2">
          <div className="section-head color-1">
            {/* <div className="section-head-icon-container">
              <FaLaptopCode className="section-head-icon"/>
            </div>  */}
            <h4 className="section-head-text">Design</h4>
          </div>
          <marquee className="techs" width="100%">
            <XD />
            <Figma />
            {/* <Azure /> */}
            {/* <GCP /> */}
          </marquee>
        </div>
      </section>
      <div className="center cta-contain">
        <h4 className="color-0">And much more!!! </h4>
        <Link to="/contact">
          <button className="mybtn-border color-2-bg color-1">Contact Us</button>
        </Link>
      </div>
    </div>
  </Layout>
)

export default Tech
