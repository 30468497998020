import React from 'react'
import logo from '../../images/graphql.svg'

const Graphql = () => {
  return (
    <div className="tech-img">
      <span className="img-contain">
        <img className="nodejs" src={logo} alt="Node.js" width="120" height="60"></img>
        <h4 className="graphql-text">Graphql</h4>
      </span>
    </div>
  )
}

export default Graphql