import React from 'react';
import logo from '../../images/gcp.svg'

const GCP = () => {
  return (
    <div className="tech-img">
      <span className="img-contain">
        <img className="nodejs" src={logo} alt="Firebase"  height="60"></img>
      </span>
    </div>
  );
};

export default GCP;