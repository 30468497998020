import React from 'react'
import logo from '../../images/xd.svg'

const XD = () => {
  return (
    <div className="tech-img">
      <span className="img-contain">
        <img className="nodejs" src={logo} alt="Adobe XD"  height="60"></img>
      </span>
    </div>
  )
}

export default XD