import React from 'react'
import logo from '../../images/aws.png'

const AWS = () => {
  return (
    <div className="tech-img">
      <span className="img-contain">
        <img className="nodejs" src={logo} alt="AWS" width="120" height="60"></img>
      </span>
    </div>
  )
}

export default AWS